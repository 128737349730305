// pages/_app.js
import { SessionProvider } from "next-auth/react";
import { AppProps as NextAppProps } from "next/app";
import ErrorBoundary from "../components/ErrorBoundary";
import "../styles/globals.css";

import {
  DehydratedState,
  Hydrate,
  MutationCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { useState } from "react";
import { Session } from "next-auth";
import { QueryParamProvider } from "use-query-params";
import ProgressBar from "next-nprogress-bar";

import AuthLayout from "../components/Layouts/AuthLayout";
import { NextAdapter } from "next-query-params";
import { OutputFormat, setDefaults } from "react-geocode";
import { Slide, toast, ToastContainer } from "react-toastify";

const setting = () => {
  setDefaults({
    key: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
    language: "en",
    region: "uk",
    outputFormat: OutputFormat.JSON,
  });
};

setting();

interface AppProps {
  session: Session;
  dehydratedState: DehydratedState;
}

function mutationErrorToaster(error: any) {
  const extensionError = error?.response?.errors[0]?.extensions;
  if (extensionError.code !== "BAD_USER_INPUT") {
    return;
  }
  extensionError?.response?.message?.forEach((message: string) => {
    toast.error(message);
  });
}
export default function App({
  Component,
  pageProps: { session, ...pageProps },
}: NextAppProps<AppProps>) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        mutationCache: new MutationCache({
          onError(error, _variables) {
            mutationErrorToaster(error);
          },
        }),
      })
  );

  // If page layout is available, use it. Else return the page
  const Layout = (Component as any).getLayout || AuthLayout;
  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps.dehydratedState}>
        <SessionProvider session={session}>
          <ErrorBoundary>
            <QueryParamProvider adapter={NextAdapter}>
              <ProgressBar
                height="4px"
                color="#B00A5E"
                options={{ showSpinner: false }}
                shallowRouting
              />
              <Layout>
                <ToastContainer
                  position="top-center"
                  autoClose={2000}
                  hideProgressBar={true}
                  transition={Slide}
                  pauseOnFocusLoss={false}
                  closeButton={false}
                />
                <Component {...pageProps} />
              </Layout>
            </QueryParamProvider>
          </ErrorBoundary>
        </SessionProvider>
      </Hydrate>
    </QueryClientProvider>
  );
}
